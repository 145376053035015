import { FC } from 'react';
import classNames from 'classnames';

import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import { H2Section } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';
import { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import BlogArticleListItem from 'src/components/ArticleItem';
import { BlogArticle, BlogArticleItemViewType, BlogArticleListLayout } from 'src/models/blogPage';

import styles from './blog-rubric-article-list.less';

interface Props {
    list: BlogArticle[];
    articleListLayout?: BlogArticleListLayout;
    title: string;
    more: string;
    rubric: string;
    topic?: string;
}

const DEFAULT_ITEMS_IN_BLOCK = 6;
const SMALL_BLOCK_COLUMNS = '4';

const getMediaHiddenClasses = (func: (breakpoint: Breakpoint) => boolean): Record<string, boolean> => {
    return {
        [styles.hideXs]: func(Breakpoint.XS),
        [styles.hideS]: func(Breakpoint.S),
        [styles.hideM]: func(Breakpoint.M),
        [styles.hideL]: func(Breakpoint.L),
    };
};

const BlogRubricArticleList: FC<Props> = ({ list, title, more, rubric, topic, articleListLayout }) => {
    const { viewType, layout, articleListCapacity } = articleListLayout || ({} as BlogArticleListLayout);
    return (
        <>
            <div className={styles.header}>
                <H2Section>{title}</H2Section>
                {more && (
                    <div
                        className={classNames(
                            getMediaHiddenClasses((breakpoint) => layout?.[breakpoint] === SMALL_BLOCK_COLUMNS)
                        )}
                    >
                        <BlokoLink disableVisited Element={Link} to={`/articles/${rubric}${topic ? `/${topic}` : ''}`}>
                            {more}
                        </BlokoLink>
                    </div>
                )}
            </div>
            <VSpacing xs={6} base={viewType === BlogArticleItemViewType.Mini ? 6 : 8} />
            <div className={styles.list}>
                {list.map((item, index) => (
                    <div
                        key={`${rubric}-${index}`}
                        className={classNames(
                            styles.itemContainer,
                            getMediaHiddenClasses(
                                (breakpoint) => index >= (articleListCapacity?.[breakpoint] || DEFAULT_ITEMS_IN_BLOCK)
                            )
                        )}
                    >
                        <Column xs="4" s="4" m="4" l="4">
                            <BlogArticleListItem item={item} viewType={viewType || BlogArticleItemViewType.Medium} />
                        </Column>
                    </div>
                ))}
            </div>
            {more && (
                <div
                    className={classNames(
                        getMediaHiddenClasses((breakpoint) => layout?.[breakpoint] !== SMALL_BLOCK_COLUMNS)
                    )}
                >
                    <VSpacing base={6} />
                    <BlokoLink disableVisited Element={Link} to={`/articles/${rubric}${topic ? `/${topic}` : ''}`}>
                        {more}
                    </BlokoLink>
                </div>
            )}
        </>
    );
};

export default BlogRubricArticleList;
