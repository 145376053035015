import { FC } from 'react';

import ArticleTag from 'src/components/ArticleItem/Tags/Tag/Tag';
import { ArticleTopic } from 'src/models/articlePage/types';

import styles from './blog-tag-cloud.less';

interface Props {
    topics: ArticleTopic[];
    hhtmFromLabel?: string;
}

const BlogTopicsTagCloud: FC<Props> = ({ topics, hhtmFromLabel }) => {
    if (!topics.length) {
        return null;
    }

    return (
        <div className={styles.tagCloud}>
            {topics.map((topicCode) => (
                <ArticleTag code={topicCode} key={topicCode} hhtmFromLabel={hhtmFromLabel} isTopic isLarge />
            ))}
        </div>
    );
};

export default BlogTopicsTagCloud;
