import { StrictMode } from 'react';
import { Route, Switch } from 'react-router-dom';

import { ColumnsWrapper } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import PageLayout, { EmptyLayout } from 'src/app/layouts/PageLayout';
import { useSelector } from 'src/hooks/useSelector';

import BlogCategoryPage from 'src/pages/Blog/components/BlogCategoryPage';
import BlogEventsCalendarPage from 'src/pages/Blog/components/BlogEventsCalendarPage';
import BlogIndexPage from 'src/pages/Blog/components/BlogIndexPage';
import BlogSearchPage from 'src/pages/Blog/components/BlogSearchPage';
import BlogPressReleasePage from 'src/pages/Blog/components/PressRelease/BlogPressReleasePage';
import BlogSpecialProjectsPage from 'src/pages/Blog/components/SpecialProjects';

const Blog: TranslatedComponent = () => {
    const pageMetaData = useSelector((state) => state.pageMetaData);

    return (
        <StrictMode>
            <PageLayout layout={EmptyLayout} {...pageMetaData}>
                <ColumnsWrapper>
                    <VSpacing base={7} xs={0} />
                    <Switch>
                        <Route path="/articles/:rubric(events)" exact>
                            <BlogEventsCalendarPage showTopicsFilter={false} />
                        </Route>
                        <Route path="/articles/:rubric(students)/:topic(events-for-students)" exact>
                            <BlogEventsCalendarPage showTopicsFilter={true} />
                        </Route>
                        <Route path="/articles/:rubric(labour-market)/:topic(companies-news)" exact>
                            <BlogPressReleasePage showTopicsFilter />
                        </Route>
                        <Route path="/articles/:rubric(guide-to-companies)/:topic(special-projects)" exact>
                            <BlogSpecialProjectsPage />
                        </Route>
                        <Route path="/articles" exact>
                            <BlogIndexPage />
                        </Route>
                        <Route path="/articles/search" exact>
                            <BlogSearchPage />
                        </Route>
                        <Route path="/articles/:rubric/:topic?">
                            <BlogCategoryPage />
                        </Route>
                    </Switch>
                    <VSpacing base={10} xs={0} />
                </ColumnsWrapper>
            </PageLayout>
        </StrictMode>
    );
};

export default Blog;
