import { FC, PropsWithChildren } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import { BlogArticle } from 'src/models/blogPage';

import { useGetArticleHref, getArticleImage } from 'src/components/ArticleItem/utils/get-article-properties';

import styles from './article-item-compact.less';

interface Props {
    item: BlogArticle;
}

const ArticleItemCompact: FC<Props & PropsWithChildren> = ({ item }) => {
    const { title } = item;
    const image = getArticleImage(item, 'preview');
    const articleHref = useGetArticleHref(item);

    return (
        <div className={styles.compact}>
            <BlokoLink Element={Link} to={articleHref} kind={LinkKind.Tertiary} title={title}>
                <div className={styles.compactPreviewContainer}>
                    {image && <img src={image} alt={title} className={styles.compactPreview} />}
                </div>
                <VSpacing base={3} />
                <Text>{title}</Text>
            </BlokoLink>
        </div>
    );
};

export default ArticleItemCompact;
