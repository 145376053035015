import { FC } from 'react';
import classNames from 'classnames';

import ArticleItemCompact from 'src/components/ArticleItem/ArticleItemCompact';
import { useSelector } from 'src/hooks/useSelector';

import styles from './blog-grid.less';

interface Props {
    large?: boolean;
}

const BlogArticleGrid: FC<Props> = ({ large }) => {
    const articles = useSelector(({ blogPage }) => blogPage.articles);

    return (
        <ul
            className={classNames(styles.grid, {
                [styles.large]: large,
            })}
        >
            {articles.main?.map((item, index) => (
                <li key={index} className={styles.card}>
                    <ArticleItemCompact item={item} />
                </li>
            ))}
            {[0, 1, 2, 3].map((index) => (
                <li
                    key={`article-grid-end-placeholder-${index}`}
                    className={classNames(styles.card, styles.placeholder)}
                />
            ))}
        </ul>
    );
};

export default BlogArticleGrid;
