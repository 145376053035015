import { FC, useId } from 'react';

import VSpacing from 'bloko/blocks/vSpacing';

import ArticleTag from 'src/components/ArticleItem/Tags/Tag/Tag';
import { BlogTag } from 'src/models/blogPage';

import styles from './topic-tags.less';

interface Props {
    tags?: BlogTag[];
    hhtmFromLabel?: string;
}

const TopicTags: FC<Props> = ({ tags, hhtmFromLabel }) => {
    const id = useId();

    if (!tags?.length) {
        return null;
    }

    return (
        <>
            <VSpacing base={3} xs={0} />
            <div className={styles.wrapper}>
                {tags.map((tag) => (
                    <ArticleTag key={`${id}-${tag.code}`} code={tag.code} hhtmFromLabel={hhtmFromLabel} />
                ))}
            </div>
            <VSpacing base={10} xs={6} />
        </>
    );
};

export default TopicTags;
