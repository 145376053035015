import { FC, PropsWithChildren } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import HSpacing from 'bloko/blocks/hSpacing';
import { H2 } from 'bloko/blocks/header';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import ArticleViewsCount from 'src/components/ArticleViewsCount';
import { useSelector } from 'src/hooks/useSelector';
import { BlogArticle } from 'src/models/blogPage';

import ArticlePublicationInfo from 'src/components/ArticleItem/ArticlePublicationInfo';
import ArticleTags from 'src/components/ArticleItem/Tags';
import { getArticleImage, useGetArticleHref } from 'src/components/ArticleItem/utils/get-article-properties';

import styles from './article-list-item.less';

interface Props {
    item: BlogArticle;
    noPublicationInfo?: boolean;
}

const ArticleListItemLarge: FC<Props & PropsWithChildren> = ({ item, noPublicationInfo }) => {
    const isViewsCountEnabled = useSelector((state) => state.blogPage.isViewsCountEnabled);
    const viewsCount = useSelector((state) => state.blogViews?.[item.code]);
    const image = getArticleImage(item, 'cover');
    const articleHref = useGetArticleHref(item);
    const isShowViewsCounter = isViewsCountEnabled && !!viewsCount && viewsCount > 0;

    return (
        <div className={styles.large}>
            <div className={styles.content}>
                <div className={styles.imageWrapper}>
                    <Column xs="4" s="3" m="3" l="4">
                        {image && (
                            <BlokoLink Element={Link} to={articleHref} kind={LinkKind.Tertiary}>
                                <div className={styles.previewContainer}>
                                    <img src={image} alt={item.title} className={styles.preview} />
                                </div>
                            </BlokoLink>
                        )}
                    </Column>
                </div>
                <div className={styles.captionWrapper}>
                    <VSpacing base={0} xs={7} />
                    <div className={styles.caption}>
                        {!!item.topics.length && (
                            <>
                                <ArticleTags topics={item.topics} hhtmFromLabel="article_card_tag" />
                                <VSpacing base={3} />
                            </>
                        )}
                        <BlokoLink Element={Link} to={articleHref} kind={LinkKind.Tertiary}>
                            <H2>{item.title}</H2>
                        </BlokoLink>
                        {(!noPublicationInfo || isViewsCountEnabled) && (
                            <>
                                <VSpacing base={3} />
                                <div className={styles.data}>
                                    {!noPublicationInfo && (
                                        <ArticlePublicationInfo publicationDate={item.publicationDate} />
                                    )}
                                    {!noPublicationInfo && isShowViewsCounter && (
                                        <>
                                            <HSpacing base={2} />
                                            <div className={styles.captionViews}>•</div>
                                            <HSpacing base={2} />
                                        </>
                                    )}
                                    {isShowViewsCounter && (
                                        <div className={styles.captionViews}>
                                            <Text size={TextSize.Small}>
                                                <ArticleViewsCount count={viewsCount} />
                                            </Text>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArticleListItemLarge;
